.download-btn {
    display: inline-block;
    padding: 10px 20px;
    background-color: #575555;
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
    border: none;
    transition: background-color 0.3s ease;
    font-weight: bold;
   
  }
  
  .download-btn:hover {
    background-color: var(--selectionArea-bg-color);
  }
  
  .download-btn:active {
    background-color: var(--selectionArea-bg-color);
  }
  