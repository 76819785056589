.pictureComponent {
  height: 500px;
  width: 500px;
}

button.pictureComponent {
  display: block;
}

.gallery-heading {
  font-size: 24px;
  color: #333;
}

.picture-container {
  display: flex;
  /* justify-content: center; */
  margin-top: 40px;
  margin-left: 30px;
}

.picture {
  width: 350px;
  height: 350px;
  margin: 10px;
  object-fit: cover;
  /* display: inline; */
}

.speedingBoat-div {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.speedingBuoyInfo {
  float: left;
  margin-left: 30px;
}

.bold {
  font-weight: bold;
}

.speedingBoatItems {
  margin-left: 25px;
}