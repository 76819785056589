
:root {
  /* --selectionArea-bg-color: rgb(82, 82, 149); */
  --selectionArea-bg-color: #001a6c;
  --selectionArea-font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}


.SelectionArea {
  background-color: var(--selectionArea-bg-color);
  width: 20dvw;
  height: 100dvh;
}

.SelectionArea Button {
  width: 100%;
  padding: 10px; 
  font-family: var(--selectionArea-font-family);
  font-weight: bold;
  font-size: 15px;
}

.SelectionArea img {
  width: 75%;
  margin: 15px;
}

.SelectionArea label {
  color: aliceblue;
  font-family: var(--selectionArea-font-family);
  font-weight: bold;
}

.activeBuoy {
  padding: 10px 0 15px 0;

}

#buoys{
  font-family: var(--selectionArea-font-family);
  font-size: 15px;
}