.buoy-tile {
    width: 300px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
    margin: 20px;
    font-family: Arial, sans-serif;
}

.buoy-tile h2 {
    font-size: 24px;
    color: #333333;
    margin-bottom: 20px;
}

.buoy-tile p {
    font-size: 18px;
    color: #666666;
    line-height: 1.6;
}

.buoy-tile p strong {
    color: #333333;
}