table {
    border-collapse: collapse;
    margin: 1em auto;
    max-width: 1170px;
    width: 100%;
  }
  
  thead {
    background-color: #575555;
    color: white;
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 0.5em;
    text-align: center;
  }
  
  th {
    font-weight: bold;
  }
  
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  /* tr:hover {
    background-color: #ddd;
  } */